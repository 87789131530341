import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import { IPartners, SubscriptionsTrialPeriod, TrialPeriod, UserSubscriptionType } from 'types/subscriptions';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';

import styles from './subscription.module.scss';

interface ISubscription {
  name: string;
  status: UserSubscriptionType;
  inTrial: boolean;
  inGrace: boolean;
  isActiveSubscription: boolean;
  recurring: boolean;
  renews: Date;
  expireDue: Date | null;
  paidUntil: Date;
  orderSubTrialPeriodLength: number;
  orderSubTrialPeriod: TrialPeriod;
  orderReseller: boolean;
  url: string;
  isFetchingReactivate: boolean;
  reseller: IPartners | null;
  cancelSubscription: (url: string) => void;
  reactivateSubscription: (url: string) => void;
}
const Subscription: React.FC<ISubscription> = ({
  name,
  status,
  inTrial,
  inGrace,
  isActiveSubscription,
  recurring,
  renews,
  expireDue,
  paidUntil,
  orderSubTrialPeriodLength,
  orderSubTrialPeriod,
  orderReseller,
  reseller,
  url,
  isFetchingReactivate,
  cancelSubscription,
  reactivateSubscription,
}) => {
  return (
    <div className={styles['subscription-details']}>
      <h4 className={styles['subscription-details-name']}>{name}</h4>
      {status === 'CANCELED' && !inTrial && inGrace && (
        <Alert icon={'circle-exclamation'} info>
          {' '}
          A payment for your current billing period is pending and you will be charged.{' '}
        </Alert>
      )}
      <div className={styles['subscription-details-description']}>
        <div className={styles['subscription-details-list']}>
          <ul>
            <li className={clsx(!isActiveSubscription && styles['text-danger'])}>
              <strong>Status:</strong>
              <span>
                {status}
                {isActiveSubscription ? (
                  <span className="fas fa-check-circle fa-lg text-success" />
                ) : (
                  <span className="fas fa-times fa-lg text-danger" />
                )}
              </span>
            </li>
            {recurring && isActiveSubscription && (
              <li>
                <strong>Automatic renewal:</strong>

                {renews && <span>{moment(renews).format('LL')}</span>}
              </li>
            )}
            {(!recurring || !isActiveSubscription) && (
              <li className={clsx(!isActiveSubscription && styles['text-danger'])}>
                <span>
                  <strong>Ends on:</strong>
                </span>
                {moment(expireDue || paidUntil).format('LL')}
              </li>
            )}
          </ul>
          <div className={styles['subscription-details-list-text']}>
            {!recurring && (
              <div>
                This is a one-time subscription, which will automatically expire on{' '}
                {moment(expireDue || paidUntil).format('LL')}. After it expires, you will be able to take a new
                subscription.
              </div>
            )}

            {!reseller && (
              <div>
                {inTrial && isActiveSubscription && (
                  <p className="small">
                    <span>
                      You are currently in your{' '}
                      {orderSubTrialPeriodLength && (
                        <span>
                          {orderSubTrialPeriodLength}-{SubscriptionsTrialPeriod[orderSubTrialPeriod]}
                        </span>
                      )}{' '}
                      free trial period. No money is charged during this time.{' '}
                      {renews && (
                        <span>
                          Your monthly subscription will be automatically renewed and paid on{' '}
                          {moment(renews).format('LL')}
                        </span>
                      )}
                    </span>
                  </p>
                )}
              </div>
            )}
            {reseller &&
              (status === 'DEACTIVATED' ? (
                <p className="small">
                  Your {reseller.name} subscription has been deactivated.
                  <span>
                    Please reactivate your subscription with
                    {reseller.name}.
                  </span>
                </p>
              ) : (
                <p className="small">
                  You can manage your subscription through{' '}
                  {reseller.site && (
                    <a href={reseller.site} target="_blank" rel="noopener noreferrer">
                      your partner provider.
                    </a>
                  )}
                  <span>your partner provider.</span>
                </p>
              ))}
            {status === 'CANCELED' && !inGrace && (
              <div>
                <p className="small">
                  This subscription is set to expire on {moment(expireDue).format('LL')}. If you change your mind, you
                  can reactivate it before {moment(expireDue).format('LL')} using the button on the right.
                </p>
                {!inTrial && inGrace && (
                  <p>
                    Your subscription has been canceled and is set to expire on {moment(expireDue).format('LL')}.
                    <span>
                      You can continue to use your subscription until
                      {moment(expireDue).format('LL')}
                    </span>
                    .
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        {!orderReseller && recurring && (
          <div className={styles['subscription-details-buttons']}>
            {!isActiveSubscription && (
              <Button
                lg
                primary
                onClick={() => reactivateSubscription(url)}
                disabled={isFetchingReactivate}
                loading={isFetchingReactivate}
              >
                Reactivate
              </Button>
            )}
            {isActiveSubscription && (
              <Button className="btn btn-danger" lg onClick={() => cancelSubscription(url)}>
                Cancel subscription
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Subscription;
