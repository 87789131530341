import * as yup from 'yup';

export const recurlyCreditCardSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .matches(/^[a-zA-Z]+$/, 'First name must only contain letters')
    .min(2, 'First name must be at least 2 characters long')
    .max(30, 'First name must be less than 30 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(/^[a-zA-Z]+$/, 'Last name must only contain letters')
    .min(2, 'Last name must be at least 2 characters long')
    .max(30, 'Last name must be less than 30 characters'),
  address: yup.string().required('Invalid address').max(50, 'Address is too long'),
  postalCode: yup
    .string()
    .required('Invalid zip code')
    .matches(/^[0-9a-zA-Z]+$/, 'Postal code must be alphanumeric')
    .min(5, 'Postal code must be at least 5 characters long')
    .max(10, 'Postal code must be less than 10 characters long'),
  city: yup
    .string()
    .required('Invalid city')
    .matches(/^[a-zA-Z\s-]+$/, 'City must only contain letters, spaces, or dashes')
    .max(50, 'City name is too long'),
});
